import Input from "components/form/input";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useTranslations } from "hooks/translations";
import { isLocal } from "lib/env";
import { read, write } from "lib/storage";
import { requiredValidator, runValidations, ValidatorFn } from "lib/validators";
import { SignupNameContentType } from "models/content/signup-name";
import { SIGNUP_NAME_KEY } from "models/user";
import { Fragment, FunctionComponent, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";

interface SignupNameContentProps extends ContentProps {
  content: SignupNameContentType;
  onSubmit: () => void;
}

const names = ["Dave", "Bob", "Julia", "Dorothy", "John", "Emily"];

export const generateRandomName = () =>
  names[Math.floor(Math.random() * names.length)];

const SignupNameContent: FunctionComponent<SignupNameContentProps> = ({
  onChange,
  onSubmit,
}) => {
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const t = useTranslations();
  const signupName = read<string>(SIGNUP_NAME_KEY);
  const originalName = state.user.firstname || signupName || "";
  const [name, setName] = useState(originalName);

  const randomise = () => {
    const name = generateRandomName();
    handleNameChange(name);
  };

  const handleNameChange = (name: string) => {
    // internal state
    setName(name);

    // store new value to the state
    state.user.firstname = name;
    state.signupDone = true;
    updateQuestionnaireState(state);

    // write to localstorage
    write(SIGNUP_NAME_KEY, name);

    // signal question level
    onChange();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const validators: ValidatorFn[] = [requiredValidator(t)];

  return (
    <Fragment>
      {isLocal() && (
        <div onClick={randomise}>[Click here to generate name]</div>
      )}
      <Input
        unique
        type="text"
        inputMode="text"
        autoFocus
        maxLength={50}
        value={name}
        enterKeyHint="go"
        validators={validators}
        placeholder={t("label_firstname")}
        onChange={handleNameChange}
        onSubmit={handleSubmit}
        autoCapitalize="words"
        autoComplete="given-name"
      />
    </Fragment>
  );
};

export default SignupNameContent;

export const signupNameContentValidator: ValidatorMethod = (state, t) => {
  const { user } = state;
  const { firstname } = user;

  // create validation
  const validators: ValidatorFn[] = [requiredValidator(t)];
  const validationResult = runValidations(firstname, validators);

  if (validationResult) {
    return "fail";
  }

  return "pass";
};

export const schema: ContentSchema = {
  Component: SignupNameContent,
  validator: signupNameContentValidator,
};
