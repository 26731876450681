import dayjs, { Dayjs } from "dayjs";

export const DAY_MAP: string[] = [
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábados",
  "domingos",
];

export const getDays = () => {
  console.info(`LOCALE = ${dayjs.locale()}`);
  const weekdays = dayjs.weekdays().map((d) => d.toLocaleLowerCase());
  const firstDayOfWeek = dayjs.localeData().firstDayOfWeek();

  if (firstDayOfWeek === 1) {
    //move the first item to last, as we index days with monday first
    weekdays.push(weekdays.shift() as any);
  }

  return weekdays;
};

export const getDayName = (day: number) => {
  const weekdays = getDays();

  return weekdays[day];
};

export const getMonthName = (month: number) => {
  const months = dayjs.months().map((m) => m.toLocaleLowerCase());
  return months[month];
};

export const getMonthNameShort = (month: number) => {
  const months = dayjs.monthsShort().map((m) => m.toLocaleLowerCase());
  return months[month];
};

export const DATE_FORMAT = "D/M/YYYY";
export const DATE_FORMAT_RFC = "YYYY-MM-DD";

export const formatShort = (date: Dayjs) => {
  return date.format(DATE_FORMAT);
};

export const formatLong = (date: Dayjs) => {
  const day = date.format("D");
  const monthName = getMonthNameShort(date.month());
  const year = date.format("YYYY");
  return [day, monthName, year].join(" ");
};
