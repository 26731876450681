import { FunctionComponent } from "react";
import Section from "components/layout/section";
import Title from "components/title";
import Description from "components/description";
import Block from "components/layout/block";
import Button from "components/button";
import { useTranslations } from "hooks/translations";
import { useNavigate } from "react-router";
import {
  createEmbeddedQuestionnaireState,
  getEmbeddedQuestionnaireVersion,
} from "lib/questionnaire";
import { EmbeddedQuestionnaireQuestion } from "models/questionnaire";
import { usePageLocale, useQuestionLink, useUILanguage } from "hooks/route";

interface BuildProgramProps {}

const BuildProgram: FunctionComponent<BuildProgramProps> = () => {
  const t = useTranslations();
  const locale = usePageLocale();
  const uiLanguage = useUILanguage();

  const url = useQuestionLink(
    getEmbeddedQuestionnaireVersion(locale),
    EmbeddedQuestionnaireQuestion,
  );
  const navigate = useNavigate();

  const handleClick = () => {
    createEmbeddedQuestionnaireState(locale, uiLanguage, undefined, undefined);
    navigate(url);
  };

  return (
    <Section>
      <Block>
        <Title mode="h2-with-h1-styles">{t("section_6_title_v28")}</Title>
        <Description>{t("section_6_description_p1_embedded")}</Description>
        <Description>{t("section_6_description_p2_embedded")}</Description>
        <Description>{t("section_6_description_p3_embedded")}</Description>
        <Button
          type="primary"
          onClick={handleClick}
          text={t("section_6_button")}
          href={url}
        ></Button>
      </Block>
    </Section>
  );
};

export default BuildProgram;
