import Input from "components/form/input";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { FunctionComponent, useEffect, useState } from "react";
import { read, write } from "lib/storage";
import { login } from "services/user";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import ActionContainer, { ActionType } from "components/layout/actions";
import {
  emailValidator,
  passwordValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { REFRESH_TOKEN, SIGNUP_EMAIL_KEY, TOKEN } from "models/user";
import {
  getNextUrl,
  parseEntitlementExists,
  parseQuestionnaireCompleted,
  parseQuestionnaireVersion,
} from "lib/restore";
import { useConfig, useUpdateConfig } from "hooks/config";
import { useQuestionnaire, useUpdateQuestionnaire } from "hooks/questionnaire";
import { useUpdateQuestionnaireState } from "hooks/state";
import { gtmUserId } from "lib/gtm";
import {
  loadCleanQuestionnaire,
  loadQuestionnaireState,
} from "services/questionnaire";
import { isStateValid } from "lib/state";
import {
  getLastSignupView,
  getProgramView,
  getVersion,
  isFinished,
  isQuestionnaireSupported,
} from "lib/questionnaire";
import { useNavigate } from "react-router";
import { fetchEntitlement } from "services/entitlement";
import { isEntitlementActive, isEntitlementExpired } from "lib/entitlement";
import { useLink, usePageLocale, useRaffleLink } from "hooks/route";

const QuestionnaireLoginWithPasswordPage: FunctionComponent = () => {
  const config = useConfig();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const t = useTranslations();
  const updateConfig = useUpdateConfig();
  const questionnaire = useQuestionnaire();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const [loaded, toggleLoaded] = useState(false);
  const fpHref = useLink("recover-password");
  const locale = usePageLocale();
  const loginSuccessLink = useLink("login-link-sent");
  const reactivateLink = useLink("questionnaire-reactivate", {
    version: questionnaire.id,
  });
  const baseLink = useRaffleLink();
  const questionLink = useLink("question");
  const version = getVersion(questionnaire);

  useEffect(() => {
    // check if we have email in the cache
    const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
    if (cachedEmail) {
      setEmail(cachedEmail);
    }
    toggleLoaded(true);
  }, []);

  const handleEmailChange = (email: string) => {
    setEmail(email);
    write(SIGNUP_EMAIL_KEY, email);
  };

  const handlePasswordChange = (password: string) => setPassword(password);

  const handleSubmit = async () => {
    // first do login
    try {
      const loginResponse = await login(email, password || "");
      const { token, refreshToken, user } = loginResponse;
      write(TOKEN, token);
      write(REFRESH_TOKEN, refreshToken);

      // overwrite config a bit
      console.info(`Replace config.uuid with UUID = ${user.uuid}`);
      config.uuid = user.uuid || "";
      config.questionnaireVersion = parseQuestionnaireVersion(loginResponse);
      config.completed = parseQuestionnaireCompleted(loginResponse);
      config.entitlementExists = parseEntitlementExists(loginResponse);
      updateConfig(config);
      // send uuid to GTM
      gtmUserId(config.uuid);
    } catch (err) {
      navigate(fpHref);
      return;
    }

    // check entitlement
    const entitlement = await fetchEntitlement();
    if (entitlement && isEntitlementActive(entitlement)) {
      const url = t("/manage-your_plan");
      return navigate(url);
    } else if (entitlement && isEntitlementExpired(entitlement)) {
      return navigate(reactivateLink);
    }

    // load existing state
    const restoredState = await loadQuestionnaireState(config);
    const lastSignupView = getLastSignupView(questionnaire);

    console.info(restoredState);
    console.info(`isStateValid = ${isStateValid(restoredState)}`);
    console.info(
      `isSupported = ${isQuestionnaireSupported(locale, restoredState.id)}`,
    );
    if (isStateValid(restoredState) === false) {
      return navigate(`${baseLink}/${version}/${lastSignupView.id}`);
    }

    // if the restored state questionnaire is not supported, continue the flow using the current state
    if (isQuestionnaireSupported(locale, restoredState.id) === false) {
      return navigate(`${baseLink}/${version}/${lastSignupView.id}`);
    }

    // the restored state if good, but would take the user before the signup
    if (restoredState.currentQuestionId < lastSignupView.id) {
      return navigate(`${baseLink}/${version}/${lastSignupView.id}`);
    }

    // restored state is valid, fetch questionnaire
    const restoredQuestionnaire = await loadCleanQuestionnaire(
      config.uuid,
      restoredState.id,
      true,
      locale,
    );
    const restoredVersion = getVersion(restoredQuestionnaire);

    // update state
    updateQuestionnaire(restoredQuestionnaire);
    updateQuestionnaireState(restoredState);

    // if the restored state is "finished"
    if (isFinished(restoredState)) {
      const programView = getProgramView(restoredQuestionnaire, restoredState);
      const link = questionLink
        .replace(":version", restoredVersion)
        .replace(":question", programView.id.toString());
      navigate(link);
      return;
    }

    // take the user to the correct screen
    const url = getNextUrl(
      restoredQuestionnaire,
      restoredState,
      restoredVersion,
      baseLink,
    );
    navigate(url);
  };

  const emailValidators: ValidatorFn[] = [
    requiredValidator(t),
    emailValidator(t),
  ];

  const passwordValidators: ValidatorFn[] = [
    requiredValidator(t),
    passwordValidator(t),
  ];

  const handleForgotPassword = () => {
    navigate(fpHref);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_button_start"),
        disabled:
          runValidations(email, [...emailValidators]) !== undefined ||
          runValidations(password, [...passwordValidators]) !== undefined,
        onClick: handleSubmit,
        type: "primary",
        href: loginSuccessLink,
      },
    },
    {
      type: "button",
      button: {
        text: t("login_button_help"),
        onClick: handleForgotPassword,
        href: fpHref,
        type: "link",
      },
    },
  ];

  if (!loaded) {
    return null;
  }

  return (
    <View title={t("login_title")} description="" footer showLogoInFooter cover>
      <Navbar logoAsLink={false} />
      <ContentContainer>
        <Title>{t("login_title")}</Title>
        <Input
          type="email"
          name="email"
          inputMode="email"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={emailValidators}
          label={t("label_email")}
          placeholder={t("label_email")}
          onChange={handleEmailChange}
          autoComplete="username"
        />
        <Input
          type="password"
          name="password"
          inputMode="text"
          autoFocus
          value={password}
          enterKeyHint="go"
          validators={passwordValidators}
          label={t("label_password")}
          placeholder={t("label_password")}
          onChange={handlePasswordChange}
          onSubmit={handleSubmit}
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireLoginWithPasswordPage;
