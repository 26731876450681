import NotFoundViewWrapper from "views/not-found/wrapper";
import QuestionnaireVersionView from "./view";
import { QuestionnaireVersion } from "models/questionnaire";
import { useParams } from "react-router";
import { isQuestionnaireSupported } from "lib/questionnaire";
import { usePageLocale } from "hooks/route";

const QuestionnaireVersionViewWrapper = () => {
  const locale = usePageLocale();
  const { version } = useParams();
  const v = version as QuestionnaireVersion;

  if (Number.isNaN(v) || !isQuestionnaireSupported(locale, v)) {
    return <NotFoundViewWrapper />;
  }

  return <QuestionnaireVersionView />;
};

export default QuestionnaireVersionViewWrapper;
