import { Fragment, FunctionComponent } from "react";
import { useConfig } from "hooks/config";
import { replaceTags, toHTML } from "lib/string";
import {
  ImageCSS,
  StyledAppRating,
  StyledAppRatingStars,
  StyledAppRatingText,
  StyledStars,
} from "./app-rating.styles";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import Image from "components/image";
import { getMediaUrl, MediaUrls } from "lib/media";
import { useView } from "hooks/view";
import { AppRatingContentType } from "models/content/app-rating";
import { useTheme } from "hooks/theme";
import { useTranslations } from "hooks/translations";

interface AppRatingContentProps extends ContentProps {
  content: AppRatingContentType;
}

const AppRatingContent: FunctionComponent<AppRatingContentProps> = ({
  content,
  tags,
}) => {
  const theme = useTheme();
  const config = useConfig();
  const view = useView();
  const translate = useTranslations();

  const { country } = config;
  const { appRatingTexts, appRatingStars = false } = content;

  const text = translate(
    appRatingTexts[country] || appRatingTexts["def"] || "n/a",
  );
  const description = replaceTags(text, tags);

  const urls = parseAppRatingUrls();

  const starsUrl = getMediaUrl("yellow-rating-5-star", {
    extension: "svg",
    size: "1x",
  });

  return (
    <StyledAppRating $theme={theme}>
      {theme === "MasterEnglish" && <StyledStars src={starsUrl} />}
      {appRatingStars && (
        <Fragment>
          {theme === "Kosmo" && (
            <StyledAppRatingStars>
              <Image urls={urls} alt="Golden Stars" css={ImageCSS} />
            </StyledAppRatingStars>
          )}
        </Fragment>
      )}
      <StyledAppRatingText $theme={theme} $view={view}>
        {toHTML(description)}
      </StyledAppRatingText>
    </StyledAppRating>
  );
};

export default AppRatingContent;

export const appRatingContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: AppRatingContent,
  validator: appRatingContentValidator,
};

export const parseAppRatingUrls = (): MediaUrls => {
  const mobile = getMediaUrl("GoldenStars", {
    extension: "png",
    size: "2x",
  });
  const mobileWebp = getMediaUrl("GoldenStars", {
    extension: "webp",
    size: "2x",
  });
  const tablet = getMediaUrl("GoldenStars", {
    extension: "png",
    size: "3x",
  });
  const tabletWebp = getMediaUrl("GoldenStars", {
    extension: "webp",
    size: "3x",
  });
  const desktop = getMediaUrl("GoldenStars", {
    extension: "png",
    size: "3x",
  });
  const desktopWebp = getMediaUrl("GoldenStars", {
    extension: "webp",
    size: "3x",
  });
  return { mobile, tablet, desktop, mobileWebp, tabletWebp, desktopWebp };
};
