import { TranslationModel } from "models/locale";

// this is used in useTranslate() hook but can be used standalone, if we need translations for known language
export const translate = (translations: TranslationModel, key: string) => {
  const translation = translations[key];
  if (!translation) {
    console.warn(`Missing translation for key: ${key}`);
    return key;
  }
  return translation;
};
