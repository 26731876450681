import { createContext, FunctionComponent, useEffect, useState } from "react";
import { TranslationModel } from "models/locale";

import es from "translations/es.json";
import pt from "translations/pt.json";
import en from "translations/en.json";

import dayjs from "dayjs";
import { usePageLocale } from "hooks/route";

// create context
export const TranslationContext = createContext<TranslationModel>(es);

const TranslationProvider: FunctionComponent<any> = ({ children }) => {
  // defaults
  const [translations, toggleTranslations] = useState<TranslationModel>(es);
  const locale = usePageLocale();

  useEffect(() => {
    // switch dayjs
    dayjs.locale(locale);
    console.info(`=== DAYJS to ${locale}`);

    // toggle translations
    switch (locale) {
      case "es":
        toggleTranslations(es);
        break;
      case "en":
        toggleTranslations(en);
        break;
      case "pt":
        toggleTranslations(pt);
        break;
    }
  }, [locale]);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
