import Description from "components/description";
import View from "components/layout/view";
import { FunctionComponent } from "react";
import Title from "components/title";
import Navbar from "components/navigation/navbar";
import ContentContainer from "components/layout/content";
import ActionContainer, { ActionType } from "components/layout/actions";
import { useTranslations } from "hooks/translations";
import { getProgramView, getVersion, isFinished } from "lib/questionnaire";
import { write } from "lib/storage";
import { RESET_AFTER_PURCHASE_KEY } from "models/user";
import { useConfig } from "hooks/config";
import { isSignupDone } from "lib/state";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { useRaffleLink, useStaticLink } from "hooks/route";
import { loadQuestionnaireState } from "services/questionnaire";
import { getNextUrl } from "lib/restore";

const QuestionnaireReactivateOrNewView: FunctionComponent = () => {
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const version = getVersion(questionnaire);
  const navigate = useNavigate();
  const t = useTranslations();
  const reactivateHref = useStaticLink(version, "reactivate");
  const confirmHref = useStaticLink(version, "new-confirm");
  const baseLink = useRaffleLink();

  const handleReactivate = () => {
    navigate(reactivateHref);
  };

  const handleNew = async () => {
    // make sure we remember to reset the status
    write(RESET_AFTER_PURCHASE_KEY, true);

    // check wether we have state stored
    const restoredState = await loadQuestionnaireState(config);

    // if we have finished the signup, then go there
    if (isSignupDone(restoredState)) {
      // if the questionnaire is finished, move to somewhere else
      if (isFinished(restoredState)) {
        const programView = getProgramView(questionnaire, restoredState);
        return navigate(`${baseLink}/${version}/${programView.id}`);
      }

      // take the user to the correct screen
      const url = getNextUrl(questionnaire, restoredState, version, baseLink);
      navigate(url);
    } else {
      // else, go to confirm page
      navigate(confirmHref);
    }
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        href: reactivateHref,
        type: "default",
        text: t("reactivate_or_new_button_reactivate"),
        onClick: handleReactivate,
      },
    },
    {
      type: "button",
      button: {
        href: confirmHref,
        type: "default",
        text: t("reactivate_or_new_button_new"),
        onClick: handleNew,
      },
    },
  ];

  return (
    <View
      title={t("reactivate_or_new_title")}
      description={t("reactivate_or_new_desc1")}
      view="light"
    >
      <Navbar />
      <ContentContainer>
        <Title>{t("reactivate_or_new_title")}</Title>
        <Description>{t("reactivate_or_new_desc1")}</Description>
        <Description>{t("reactivate_or_new_desc2")}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireReactivateOrNewView;
