import { FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import Tiles from "components/tiles";
import { ProductModel, PRODUCT_KEY } from "models/product";
import { read } from "lib/storage";
import { getOnetimeFee, getSubscriptionFee } from "lib/price";
import { QuestionnaireVersion, VERSION_KEY } from "models/questionnaire";
import Navbar from "components/navigation/navbar";
import { gtmPurchaseDone, gtmSubcription } from "lib/gtm";
import { useConfig } from "hooks/config";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { RESET_AFTER_PURCHASE_KEY, SIGNUP_EMAIL_KEY } from "models/user";
import { getControlQuestionnaireVersion, getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { usePageLocale, useStaticLink } from "hooks/route";
import { StaticViewIdMap } from "models/question";

const QuestionnaireThankYouView: FunctionComponent = () => {
  const locale = usePageLocale();
  const version =
    read<QuestionnaireVersion>(VERSION_KEY) ||
    getControlQuestionnaireVersion(locale);
  const questionnaire = useQuestionnaire();
  const t = useTranslations();
  const config = useConfig();
  const navigate = useNavigate();

  // read product from localStorage, should always exist
  const product = read<ProductModel>(PRODUCT_KEY);
  const href = useStaticLink(getVersion(questionnaire), "verify-email");

  useEffect(() => {
    // if we need to reset user's progress, do it now
    if (read<boolean>(RESET_AFTER_PURCHASE_KEY) === true) {
      console.info("TODO: disabled for now");
      //resetUser(); // fire and forget
      // skip
    }
  }, []);

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdMap["thank-you"],
    });
  }, []);

  const handleSubmit = () => {
    navigate(href);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("post_purchase_button1"),
        type: "primary",
        onClick: handleSubmit,
        href,
      },
    },
  ];

  // sending events to GTM
  useEffect(() => {
    if (product) {
      const email = read<string>(SIGNUP_EMAIL_KEY) || "";
      const { currency, productType } = product;

      // me_web_Purchase every time a purchase is made
      gtmPurchaseDone(
        email,
        productType,
        productType === "onetime"
          ? getOnetimeFee(product)
          : getSubscriptionFee(product),
        currency,
        version
      );

      //  only for the instalment product
      if (productType == "subscription") {
        gtmSubcription(
          email,
          productType,
          getSubscriptionFee(product),
          currency,
          version
        );
      }
    }
  }, []);

  return (
    <View
      cover
      view="dark"
      description={t("post_purchase_p1")}
      title={t("post_purchase_title")}
    >
      <Navbar />
      <Tiles />
      <ContentContainer>
        <Title>{t("post_purchase_title")}</Title>
        <Description>{t("post_purchase_p1")}</Description>
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireThankYouView;
