import Title from "components/title";
import { replaceTags } from "lib/string";
import { TitleContentType } from "models/content/title";
import { FunctionComponent } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { useTranslations } from "hooks/translations";

interface TitleContentProps extends ContentProps {
  content: TitleContentType;
}

const TitleContent: FunctionComponent<TitleContentProps> = ({
  content,
  tags,
}) => {
  const translate = useTranslations();
  const title = replaceTags(translate(content.text), tags);
  return <Title>{title}</Title>;
};

export default TitleContent;

export const titleContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: TitleContent,
  validator: titleContentValidator,
};
