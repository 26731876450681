import styled, { css } from "styled-components";

export const StyledSwitcher = styled.div`
  position: fixed;
  left: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  background-color: black;
  z-index: 1000;
  gap: 0.5rem;
  border: 3px solid red;
`;

export const StyledLocale = styled.div<any>`
  font-size: 2rem;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: uppercase;

  ${(props) =>
    props.$active &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
