import { FunctionComponent, useEffect } from "react";
import { useConfig, useUpdateConfig } from "hooks/config";
import Loader from "components/loader";
import { useUpdateQuestionnaireState } from "hooks/state";
import { useUpdateQuestionnaire } from "hooks/questionnaire";
import { QuestionnaireVersion, TARGET_KEY } from "models/questionnaire";
import { write } from "lib/storage";
import { useNavigate, useSearchParams } from "react-router";
import { getNextUrl, restoreSession } from "lib/restore";
import {
  getControlQuestionnaireVersion,
  getProgramView,
  getVersion,
  isFinished,
  isQuestionnaireSupported,
} from "lib/questionnaire";
import {
  loadCleanQuestionnaire,
  loadQuestionnaireState,
  saveQuestionnaireState,
} from "services/questionnaire";
import { buildState, isStateValid } from "lib/state";
import { fetchEntitlement } from "services/entitlement";
import { isEntitlementActive, isEntitlementExpired } from "lib/entitlement";
import { useLink, usePageLocale, useRaffleLink } from "hooks/route";

// THIS REQUIRES BETTER LOGIC ==> CHECK THE VALUE IS A VALID VARIANT NUMBER
const parseTarget = (searchParams: URLSearchParams) => {
  const tempTarget = searchParams.get("target");
  if (!tempTarget || tempTarget.length > 2) {
    return undefined;
  }
  return tempTarget as QuestionnaireVersion;
};

const ForgotPasswordView: FunctionComponent = () => {
  const navigate = useNavigate();
  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const [searchParams] = useSearchParams();
  const locale = usePageLocale();
  const baseLink = useRaffleLink();
  const linkExpiredLink = useLink("login-expired");
  const tempToken = searchParams.get("token");
  const handleLink = useLink("handle-your-sub");
  const reactivateLink = useLink("questionnaire-reactivate");

  // variant override mechanism
  const target = parseTarget(searchParams);

  useEffect(() => {
    const init = async () => {
      if (!tempToken) {
        console.warn("This page requires a token.");
        return;
      }

      try {
        // restore session, modifies config possibly
        await restoreSession({ config, updateConfig, tempToken });
      } catch (err) {
        // store target to storate
        write(TARGET_KEY, target);

        // link expired
        navigate(linkExpiredLink);
        return;
      }

      // load existing state
      let restoredState = await loadQuestionnaireState(config);

      // if the restored state is invalid, reset the state
      if (isStateValid(restoredState) === false) {
        restoredState = buildState(locale, config);
        restoredState.id = getControlQuestionnaireVersion(locale);

        // mark signup done, so we won't go there again
        restoredState.signupDone = true;
      }

      // if we have target, use that on (resets to fresh state)
      if (target) {
        // change variant to target
        restoredState.id = target;

        // save change to server
        const tempQuestionnare = await loadCleanQuestionnaire(
          config.uuid,
          target,
          false,
          locale,
        );
        await saveQuestionnaireState(tempQuestionnare, restoredState, true);
      }

      // if the restored state questionnaire is not supported, set to control
      if (isQuestionnaireSupported(locale, restoredState.id) === false) {
        restoredState = buildState(locale, config);
        restoredState.id = getControlQuestionnaireVersion(locale);

        // mark signup done, so we won't go there again
        restoredState.signupDone = true;
      }

      // check entitlement
      const entitlement = await fetchEntitlement();
      if (entitlement && isEntitlementActive(entitlement)) {
        return navigate(handleLink);
      } else if (entitlement && isEntitlementExpired(entitlement)) {
        // pipe discount attribute to reactivate view
        const url = reactivateLink.replace(":version", restoredState.id);
        return navigate(url);
      }

      // restored state is valid, fetch questionnaire
      const restoredQuestionnaire = await loadCleanQuestionnaire(
        config.uuid,
        restoredState.id,
        true,
        locale,
      );
      const version = getVersion(restoredQuestionnaire);

      // update state
      updateQuestionnaire(restoredQuestionnaire);
      updateQuestionnaireState(restoredState);

      // if the restored state is "finished"
      if (isFinished(restoredState)) {
        const programView = getProgramView(
          restoredQuestionnaire,
          restoredState,
        );
        return navigate(`${baseLink}/${version}/${programView.id}}`);
      }

      // take the user to the correct screen
      const url = getNextUrl(
        restoredQuestionnaire,
        restoredState,
        version,
        locale,
      );
      navigate(url);
    };
    init();
  }, [tempToken]);

  return <Loader />;
};

export default ForgotPasswordView;
