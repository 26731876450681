export type CountryType =
  | "US"
  | "MX"
  | "AR"
  | "EC"
  | "SV"
  | "PA"
  | "PR"
  | "BO"
  | "CL"
  | "CO"
  | "CR"
  | "DO"
  | "GT"
  | "HN"
  | "NI"
  | "PY"
  | "PE"
  | "UY"
  | "VE";

export type LocaleType = "es" | "pt" | "en";

const localesFromEnv = import.meta.env.VITE_PUBLIC_LOCALES?.split(
  ","
) as LocaleType[];

// this is a list of allowed locales
export const Locales: LocaleType[] = localesFromEnv || ["es", "pt", "en"];
export const FallbackLocale: LocaleType = "es";

export type Language = "en-US" | "es-419" | "pt-BR";
export type TranslateFn = (key: string) => string;

export type TranslationModel = {
  [key in string]: string;
};
