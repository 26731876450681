import { useQuestionnaire } from "hooks/questionnaire";
import { FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import {
  StyledBeginMonth,
  StyledEndMonth,
  StyledChartContainer,
  StyledMonthNumber,
  StyledMonths,
  StyledProgramLength,
  StyledProgramPromiseContent,
  StyledChartContainerTablet,
} from "./program-promise.styles";
import dayjs from "dayjs";
import { getMonthNameShort } from "lib/date";
import { capitalize } from "lib/string";
import { useTranslations } from "hooks/translations";
import Lottie from "components/lottie";
import { loadLottieFile } from "lib/lottie";
import { getStudySchedule, getInitialLevelSelection } from "lib/question";
import { ValidatorMethod } from "../../validations";
import { useQuestionnaireState } from "hooks/state";
import { ProgramPromiseContentType } from "models/content/program-promise";
import { useView } from "hooks/view";
import { useTheme } from "hooks/theme";

interface ProgramPromiseContentProps extends ContentProps {
  content: ProgramPromiseContentType;
}

const ProgramPromiseContent: FunctionComponent<ProgramPromiseContentProps> = ({
  content,
}) => {
  const [animationData, setAnimationData] = useState();
  const [animationDataTablet, setAnimationDataTablet] = useState();

  const questionnaire = useQuestionnaire();
  const theme = useTheme();
  const state = useQuestionnaireState();
  const view = useView();
  const userLevelObject = getInitialLevelSelection(questionnaire, state);
  const t = useTranslations();
  const { programPromiseOptions } = content;
  const studySchedule = getStudySchedule(questionnaire, state);

  // show range or not
  const range =
    programPromiseOptions && programPromiseOptions.range !== undefined
      ? programPromiseOptions.range
      : true;

  // use primarly props, secondarily studySchedule and if nothing else, default to 9
  let months = programPromiseOptions?.months;
  if (!months) {
    // rely on study schedule
    months = studySchedule ? studySchedule.months : 9;
  }

  const beginMonth = capitalize(getMonthNameShort(dayjs().month()));
  const endMonth = capitalize(
    getMonthNameShort(dayjs().add(months, "months").subtract(1, "day").month()),
  );

  useEffect(() => {
    const fn = async () => {
      console.info("hello");
      console.info(userLevelObject);
      if (userLevelObject) {
        const toMatch =
          userLevelObject.initialLevelType || userLevelObject.userLevel;
        console.info(toMatch);
        switch (toMatch) {
          case "beginner":
            const animationData = await loadLottieFile(
              view === "light"
                ? "Beginner_ProgressVisualisation_Mobile"
                : "Beginner_ProgressVisualisation_Mobile_White",
            );
            const animationDataTablet = await loadLottieFile(
              view === "light"
                ? "Beginner_ProgressVisualisation_Tablet"
                : "Beginner_ProgressVisualisation_Tablet_White",
            );
            setAnimationData(animationData);
            setAnimationDataTablet(animationDataTablet);
            break;
          case "basic":
            const animationData2 = await loadLottieFile(
              view === "light"
                ? "Basic_ProgressVisualisation_Mobile"
                : "Basic_ProgressVisualisation_Mobile_White",
            );
            const animationDataTablet2 = await loadLottieFile(
              view === "light"
                ? "Basic_ProgressVisualisation_Tablet"
                : "Basic_ProgressVisualisation_Tablet_White",
            );
            setAnimationData(animationData2);
            setAnimationDataTablet(animationDataTablet2);
            break;
          case "intermediate":
            const animationData3 = await loadLottieFile(
              view === "light"
                ? "Intermediate_ProgressVisualisation_Mobile"
                : "Intermediate_ProgressVisualisation_Mobile_White",
            );
            const animationDataTablet3 = await loadLottieFile(
              view === "light"
                ? "Intermediate_ProgressVisualisation_Tablet"
                : "Intermediate_ProgressVisualisation_Tablet_White",
            );
            setAnimationData(animationData3);
            setAnimationDataTablet(animationDataTablet3);
            break;
          case "upperintermediate":
          case "advanced":
            const animationData4 = await loadLottieFile(
              view === "light"
                ? "UpperIntermediate_ProgressVisualisation_Mobile"
                : "UpperIntermediate_ProgressVisualisation_Mobile_White",
            );
            const animationDataTablet4 = await loadLottieFile(
              view === "light"
                ? "UpperIntermediate_ProgressVisualisation_Tablet"
                : "UpperIntermediate_ProgressVisualisation_Tablet_White",
            );
            setAnimationData(animationData4);
            setAnimationDataTablet(animationDataTablet4);
            break;

          default:
            throw new Error("unhandled user level");
        }
      }
    };
    fn();
  }, []);

  return (
    <StyledProgramPromiseContent>
      <StyledMonths>
        {range && (
          <StyledBeginMonth $theme={theme} $view={view}>
            {beginMonth}
          </StyledBeginMonth>
        )}
        <StyledProgramLength $view={view}>
          <StyledMonthNumber $view={view}>{months}</StyledMonthNumber>
          &nbsp;{t("months")}
        </StyledProgramLength>
        {range && (
          <StyledEndMonth $theme={theme} $view={view}>
            {endMonth}
          </StyledEndMonth>
        )}
      </StyledMonths>
      <StyledChartContainer>
        {animationData && <Lottie animationData={animationData} />}
      </StyledChartContainer>
      <StyledChartContainerTablet>
        {animationDataTablet && <Lottie animationData={animationDataTablet} />}
      </StyledChartContainerTablet>
    </StyledProgramPromiseContent>
  );
};

export default ProgramPromiseContent;

export const programPromiseContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: ProgramPromiseContent,
  validator: programPromiseContentValidator,
};
