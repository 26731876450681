import { GuaranteeContentType } from "models/content/guarantee";
import { FunctionComponent, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import {
  StyledGuarantee,
  StyledImage,
  StyledLabel,
  StyledText,
  StyledWhite,
  StyledWrapper,
} from "./guarantee.styles";
import ViewProvider from "providers/view";
import { createLinkTag, replaceTags, toHTML } from "lib/string";
import { TagMap } from "lib/tag";
import { buildSchema } from "../factory";
import Popup from "components/layout/popup";
import { QuestionnairePopupShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { getMediaUrl } from "lib/media";
import { useView } from "hooks/view";
import { useTheme } from "hooks/theme";
import { getVersion } from "lib/questionnaire";
import { QuestionnaireVersion } from "models/questionnaire";
import ParagraphsContent from "./paragraphs";
import { useTranslations } from "hooks/translations";

interface GuaranteeContentProps extends ContentProps {
  content: GuaranteeContentType;
}

const GuaranteeContent: FunctionComponent<GuaranteeContentProps> = ({
  content,
  tags,
  initial,
}) => {
  const theme = useTheme();
  const view = useView();
  const questionnaire = useQuestionnaire();
  const version = getVersion(questionnaire);
  const config = useConfig();
  const [open, toggleOpen] = useState(false);
  const t = useTranslations();

  const {
    guaranteeOptions = { label: undefined, text: undefined, modal: undefined },
  } = content;
  const { label, text, modal } = guaranteeOptions;

  // add link tag
  const extendedTags: TagMap = {
    ...tags,
    HANDLE: createLinkTag(
      "",
      modal && modal.handle ? t(modal.handle) : "",
      false,
    ),
  };
  const parsedText = replaceTags(t(text || ""), extendedTags);

  // if the user clicks popup open handle
  const handleClick = (e: any) => {
    e.preventDefault();

    if (e.target && e.target.tagName === "A") {
      // only accept clicks on <a> tags
      toggleOpen(true);

      // send event
      QuestionnairePopupShownEvent({
        uuid: config.uuid,
        questionnaire,
        popupId: "GuaranteePopupOpened",
      });
    }
  };

  const renderModal = () => {
    if (!modal) {
      return null;
    }

    const { title, contents } = modal;

    return (
      <Popup title={title} onClose={() => toggleOpen(false)}>
        <ViewProvider type="light">
          {contents.map((c, index) => {
            const schema = buildSchema(c);
            const { Component } = schema;
            return (
              <Component
                key={index}
                content={c}
                tags={tags}
                initial={initial}
                onChange={() => {}}
                onSubmit={() => {}}
                handleNavigation={async () => {}}
                handleScroll={() => {}}
              />
            );
          })}
        </ViewProvider>
      </Popup>
    );
  };

  if (version === QuestionnaireVersion.V77) {
    return (
      <StyledGuarantee>
        <div onClick={handleClick}>
          <ParagraphsContent
            content={{
              type: "paragraphs",
              paragraphs: [{ id: 1, label, text: t(text || "") }],
            }}
            tags={extendedTags}
            initial={false}
            onChange={() => {}}
            onSubmit={() => {}}
            handleNavigation={async () => {}}
            handleScroll={() => {}}
          />
        </div>
        {open && renderModal()}
      </StyledGuarantee>
    );
  }

  return (
    <StyledGuarantee>
      <StyledWrapper $theme={theme}>
        <StyledImage src={getMediaUrl("Guarantee", { extension: "svg" })} />
      </StyledWrapper>
      <ViewProvider type="light">
        <StyledWhite $view={view} $theme={theme}>
          <StyledLabel>{label}</StyledLabel>
          <div onClick={handleClick}>
            <StyledText>{toHTML(parsedText)}</StyledText>
          </div>
        </StyledWhite>
      </ViewProvider>
      {open && renderModal()}
    </StyledGuarantee>
  );
};

export default GuaranteeContent;

export const guaranteeContentValidator: ValidatorMethod = () => {
  return "pass";
};

export const schema: ContentSchema = {
  Component: GuaranteeContent,
  validator: guaranteeContentValidator,
};
