import { getDays } from "lib/date";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import Nudge from "components/nudge";
import { StyledDescription } from "./day-selection.styles";
import { capitalize, replaceTags } from "lib/string";
import { useTranslations } from "hooks/translations";
import MultiSelection from "components/form/multi-selection";
import { ValidatorMethod } from "../../validations";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { DaySelectionContentType } from "models/content/day-selection";
import { useTheme } from "hooks/theme";

interface DaySelectionContentProps extends ContentProps {
  content: DaySelectionContentType;
}

const DaySelectionContent: FunctionComponent<DaySelectionContentProps> = ({
  content,
  onChange,
}) => {
  const state = useQuestionnaireState();
  const theme = useTheme();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const [selected, toggleSelected] = useState<number[]>([]);
  const [nudge, toggleNudge] = useState(false);
  const { daySelectionOptions } = content;
  const t = useTranslations();

  // find out days required, with a length fallback
  const daysRequired = daySelectionOptions?.days || 5;

  useEffect(() => {
    // always start from clean state, do not reuse browser state
  }, []);

  // days
  console.info("foo");
  const DAY_MAP = getDays();

  // compose options for the multiselection
  const options = DAY_MAP.map((day, id) => {
    return {
      id,
      text: capitalize(day),
    };
  });

  const handleChoose = (id: number) => {
    if (selected.includes(id)) {
      toggleSelected([...selected.filter((s) => s !== id)]);
      state.days = [...(state.days || []).filter((sd) => sd !== id)];
    } else {
      if (selected.length !== daysRequired) {
        const newSelected = [...selected, id].sort();
        toggleSelected(newSelected);
        state.days = newSelected;
      } else {
        toggleNudge(true);
        setTimeout(() => toggleNudge(false), 500);
      }
    }

    // persist
    updateQuestionnaireState(state);

    // signal
    onChange();
  };

  // find localised texts, fallback to old "lokalize" version
  const inprogressText =
    (daySelectionOptions && t(daySelectionOptions.textInProgress)) ||
    t("dayselection_in_progres");
  const doneText =
    (daySelectionOptions && t(daySelectionOptions.textDone)) ||
    t("dayselection_done");

  // show the correct counter
  const counter = replaceTags(
    selected.length < daysRequired ? inprogressText : doneText,
    {
      DAYS: daysRequired.toString(),
    },
  );

  return (
    <Fragment>
      <Nudge nudge={nudge}>
        <StyledDescription
          $blue={
            (theme === "Kosmo" && selected.length === daysRequired) ||
            theme === "HablaIngles"
          }
        >
          {counter}
        </StyledDescription>
      </Nudge>
      <MultiSelection
        selected={selected}
        options={options}
        onChoose={handleChoose}
      />
    </Fragment>
  );
};

export default DaySelectionContent;

export const daySelectionContentValidator: ValidatorMethod = (
  state,
  _t,
  content,
) => {
  // find out days required, with a length fallback
  const { daySelectionOptions } = content as DaySelectionContentType;
  const daysRequired = daySelectionOptions?.days || 5;
  const { days = [] } = state;
  const result = days.length >= daysRequired ? "pass" : "fail";
  return result;
};

export const schema: ContentSchema = {
  Component: DaySelectionContent,
  validator: daySelectionContentValidator,
};
