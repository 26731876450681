import ConfigProvider from "providers/config";
import QuestionnaireQuestionView from "./view";
import QuestionnaireProvider from "providers/questionnaire";
import { useParams } from "react-router";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import QuestionProvider from "providers/question";
import ProductsProvider from "providers/products";
import NotFoundViewWrapper from "views/not-found/wrapper";
import { isQuestionnaireSupported } from "lib/questionnaire";
import { usePageLocale } from "hooks/route";

const QuestionnaireQuestionViewWrapper = () => {
  const locale = usePageLocale();
  const { version: v, question: q } = useParams();
  const version = v as QuestionnaireVersion;
  const questionId = parseInt(q as string);

  if (Number.isNaN(questionId) || !isQuestionnaireSupported(locale, version)) {
    return <NotFoundViewWrapper />;
  }

  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider version={version} questionId={questionId}>
          <QuestionProvider questionId={questionId}>
            <ProductsProvider>
              <QuestionnaireQuestionView />
            </ProductsProvider>
          </QuestionProvider>
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default QuestionnaireQuestionViewWrapper;
