import dayjs from "dayjs";
import { DATE_FORMAT_RFC } from "lib/date";
import { cleanJSON } from "lib/json";
import { calculateUserLevel, getStudySchedule } from "lib/question";
import {
  getControlQuestionnaireVersion,
  isQuestionnaireSupported,
} from "lib/questionnaire";
import { request } from "lib/request";
import { ConfigModel } from "models/config";
import { QuestionnaireModel, QuestionnaireVersion } from "models/questionnaire";
import { StateModel } from "models/state";
import { sendGetQuestionnaireEvent } from "./event";
import { LocaleType } from "models/locale";
//import { buildUser } from "lib/state";

interface QuestionnaireStateResponseModel {
  state: StateModel;
}

// returns both questionnaire and state (which is optional)
export const loadQuestionnaireState = async (config: ConfigModel) => {
  const { uuid } = config;

  // use this to test different state models
  /*   return {
    id: QuestionnaireVersion.V69,
    signupDone: true,
    finished: false,
    currentQuestionId: 2,
    visitedQuestionIds: [2],
    selectedOptions: [],
    user: buildUser(config),
  } as StateModel;
 */
  // make request to load the saved questionnaire state
  const response = await request<QuestionnaireStateResponseModel>({
    method: "GET",
    url: "/v1/questionnaire",
    params: { uuid },

    // authenticated, won't work otherwise
    authenticated: true,
  });

  // return cleaned questionnaire and state
  return cleanJSON<StateModel>(response.data.state);
};

// fetch a clean version of questionnaire from CDN
export const loadCleanQuestionnaire = async (
  uuid: string,
  version: QuestionnaireVersion,
  sendEvent: boolean,
  locale: LocaleType,
) => {
  // sanity check
  if (!version || isQuestionnaireSupported(locale, version) === false) {
    version = getControlQuestionnaireVersion(locale);
  }

  // this makes TS files dynamic bundles
  const { default: json } = await import(`../questionnaires/v${version}.ts`);

  // send event to the backend (fire and forget)
  if (sendEvent) {
    sendGetQuestionnaireEvent({ uuid, questionnaireVersion: version });
  }

  if (!json) {
    throw new Error("Failed to load clean questionnaire!");
  }

  return json as QuestionnaireModel;
};

// this one only saves "state" object
export const saveQuestionnaireState = async (
  questionnaire: QuestionnaireModel,
  state: StateModel,
  finished: boolean,
) => {
  // create a clone, so we don't modify the original state
  const newState: StateModel = JSON.parse(JSON.stringify(state));

  // remove password so we don't send it to the server
  delete newState.user?.password;

  // add finished flag
  newState.finished = finished;
  newState.finishedTimestamp =
    newState.finishedTimestamp || dayjs().toISOString();

  // add studySchedule and programEndDate
  const studySchedule = getStudySchedule(questionnaire, state);
  if (studySchedule) {
    newState.studySchedule = studySchedule;
    newState.programEndDate = dayjs()
      .add(studySchedule.months, "month")
      .subtract(1, "day")
      .format(DATE_FORMAT_RFC);
  }

  // send to server
  const response = await request<QuestionnaireModel>({
    method: "POST",
    url: "/v1/questionnaire",
    data: {
      state: newState,
      userLevel: calculateUserLevel(questionnaire, state),
    },
  });
  return response;
};
